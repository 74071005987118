import './App.css';
import Testimonials from './assets/Testimonials/Testimonials';
import Footer from './components/Footer/Footer';
import Join from './components/Join/Join';
import Plans from './components/Plans/Plans';
import Program from './components/Programs/Program';
import Reason from './components/Reasons/Reason';
import Hero from './components/hero/Hero';
function App() {
  return (
    <div className="App">
         <Hero/>
         <Program/>
         <Reason/>
         <Plans/>
         <Testimonials/>
         <Join/>
         <Footer/>
    </div>
  );
}

export default App;
